#e-commerce-order {

    // Sidenav
    .sidenav {
        width: 320px;
        min-width: 320px;
        max-width: 320px;
        padding: 0;

        &._md-locked-open {
            width: 320px;
            min-width: 320px;
            max-width: 320px;
            overflow: hidden;
        }

        .header {
            // height: 120px;
            // min-height: 120px;
            // max-height: 120px;
            padding: 24px 24px;

            .title {
                font-size: 15px;
                margin-bottom: 8px;
            }
        }

        .content {
            position: relative;
            background: #FFFFFF;
        }

        .comment-list {
            height: 100%;
            min-height: 100%;
            max-height: 100%;
        }

        .comment-form {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #f5f5f5;
            border-top: 1px solid rgba(0,0,0,.08);

            md-input-container {
                margin: 0;
                padding-right: 16px;

                textarea {
                    overflow: auto;
                    max-height: 80px;
                    transition: height 200ms ease;
                    &.grow {
                        height: 80px;
                    }
                }

                .md-errors-spacer {
                    display: none;
                }
            }

            .md-button {
                margin: 0;
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #e-commerce-order {
        .sidenav {
            .header {
                height: 120px;
                min-height: 120px;
                max-height: 120px;
            }
        }
    }
}
