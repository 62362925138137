.autocomplete-symphony-search-template {
    li {
        border-bottom: 1px solid #ccc;
        height: auto;
        padding-top: 8px;
        padding-bottom: 8px;
        white-space: normal;
        &:last-child {
            border-bottom-width: 0;
        }
    }
    .item-title, .item-metadata {
        display: block;
        line-height: 2;
    }
    .item-title md-icon {
        height: 18px;
        width: 18px;
    }
}
