/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/scss/my.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/clear-autocomplete/clear-autocomplete.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/my-symphony-search/my-symphony-search.scss";
@import "main/pages/e-commerce/views/order/templates/comments-sidenav.scss";
@import "main/pages/stock-management/views/warehouses/views/container/styles.scss";
@import "main/pages/e-commerce/views/order/dialogs/browse-products/browse-products.scss";
@import "main/pages/stock-management/views/stock-receiving/views/purchase-orders/styles.scss";
@import "quick-panel/quick-panel.scss";
@import "toolbar/toolbar.scss";
@import "main/components/charts/charts.scss";
@import "main/components/comments/comments.scss";
@import "quick-panel/tabs/activity/activity-tab.scss";
@import "quick-panel/tabs/chat/chat-tab.scss";
@import "quick-panel/tabs/today/today-tab.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "main/pages/auth/login/login.scss";
@import "main/pages/auth/login-v2/login-v2.scss";
@import "main/pages/auth/register-v2/register-v2.scss";
@import "main/pages/e-commerce/views/order/style.scss";
@import "main/pages/e-commerce/views/order/timeline.scss";
@import "main/pages/e-commerce/views/orders/style.scss";
@import "main/pages/tools/views/builder/styles.scss";
// endinjector