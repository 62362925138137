#login {
    height: 100%;
    // background: url('/assets/images/backgrounds/march.jpg') no-repeat;
    // background-size: cover;

    @media (max-width: 640px) {
        .fullscreen-bg {
            background-image: url('/assets/images/backgrounds/video-frame.png');
        }

        .fullscreen-bg__video {
            display: none;
        }
    }

    @media (min-aspect-ratio: 16/9) {
        .fullscreen-bg__video {
            width: 100%;
            height: auto;
        }
    }

    @media (max-aspect-ratio: 16/9) {
        .fullscreen-bg__video {
            width: auto;
            height: 100%;
        }
    }

    .fullscreen-bg {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        z-index: -100;
    }

    .fullscreen-bg__video {
        position: absolute;
        top: 0;
        left: 0;
        // width: 100%;
        height: auto;
    }

    #login-form-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        #login-form {
            width: 384px;
            max-width: 384px;
            padding: 32px;
            background: #FFFFFF;
            text-align: center;

            .logo {
                width: 200px;
                height: 128px;
                margin: 32px auto;
                background-image: url('http://uk.castrads.com/workspace/images/Castrads_Stacked_Wordmark_Black_logo.jpg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;                
            }

            .title {
                font-size: 17px;
                margin: 16px 0 32px 0;
            }

            form {
                width: 100%;
                text-align: left;

                md-checkbox {
                    margin: 0;
                }

                .remember-forgot-password {
                    font-size: 13px;
                    margin-top: 8px;

                    .remember-me {
                        margin-bottom: 16px
                    }

                    .forgot-password {
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 16px
                    }
                }

                .submit-button {
                    width: 220px;
                    margin: 16px auto;
                    display: block;
                }
            }

            .register {
                margin: 32px auto 24px auto;
                width: 250px;
                font-weight: 500;

                .text {
                    margin-right: 8px;
                }

                .link {

                }
            }

            .separator {
                font-size: 15px;
                font-weight: 600;
                margin: 24px auto 16px auto;
                position: relative;
                overflow: hidden;
                width: 100px;
                color: rgba(0, 0, 0, 0.54);

                .text {
                    display: inline-block;
                    vertical-align: baseline;
                    position: relative;
                    padding: 0 8px;
                    z-index: 9999;

                    &:before, &:after {
                        content: '';
                        display: block;
                        width: 30px;
                        position: absolute;
                        top: 10px;
                        border-top: 1px solid rgba(0, 0, 0, 0.12);
                    }

                    &:before {
                        right: 100%;
                    }

                    &:after {
                        left: 100%;
                    }
                }
            }

            .md-button {

                &.google,
                &.facebook {
                    width: 192px;
                    text-transform: none;
                    color: #FFFFFF;
                    font-size: 13px;

                    md-icon {
                        color: #FFFFFF;
                        margin: 0 8px 0 0;
                    }
                }

                &.google {
                    background-color: #D73D32;
                }

                &.facebook {
                    background-color: rgb(63, 92, 154);
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #login {

        #login-form-wrapper {
            padding: 16px;

            #login-form {
                padding: 24px;
                width: 100%;

                form {

                    .md-button {
                        width: 90%;
                    }
                }

                .md-button {

                    &.google,
                    &.facebook {
                        width: 80%;
                    }
                }
            }
        }
    }

}