.md-list-item-text {
    padding: 10px 0;
}

.comments-list {
    overflow: auto;
    // max-height: 300px;
}

.custom-trix {
    height: 200px;
    max-height: 200px;
    overflow-y: auto;
}

.save-button {
    bottom: 20px;
    right: 5px; 
}
