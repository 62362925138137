#e-commerce-orders {
    .table {
        .status {
            font-size: 12px;
            white-space: nowrap;
            padding: 2px 5px;
            border-radius: 2px;
        }
    }
}

#e-commerce-orders .center .header .h1 md-icon {
    margin-right: 12px;
}

