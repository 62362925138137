.ta-root {
    display: block;

    .ta-scroll-window {

        &.form-control {
            border: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    &.focussed {

        > .ta-scroll-window{

            &.form-control {
                outline: 0px solid transparent;
                border: 1px solid rgba(0, 0, 0, 0.12);
                box-shadow: none;
            }
        }
    }

    textarea {

        &.ta-editor {

            &.ta-html {
                resize: vertical;
                width: 100% !important;
                border: 1px solid rgba(0, 0, 0, 0.12);
                padding: 16px;
            }
        }
    }

    .ta-toolbar {
        border: 1px solid rgba(0,0,0,0.12);
        border-bottom: none;
        background: material-color('grey', '100');
        padding-left: 5px;

        .ta-group {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            #toolbarWC,
            #toolbarCC {
                display: inline-block !important;
                line-height: 24px;
            }

            .md-button {
                width: 40px;
                min-width: 40px;
                line-height: 14px;
                height: 36px;
                min-height: 36px;
                padding: 6px;
                margin: 4px 0;

                i {
                    font-size: 20px;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                }
            }
        }
    }
}
