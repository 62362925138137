#e-commerce-order {

    .find-item {
        margin-top: 27px;
    }

    .center {

        .content {
            background: inherit;
            padding: 0;

            md-tabs-wrapper {
                md-pagination-wrapper {
                    margin: 0 16px;
                }
            }
        }

        .header {
            .header-content {
                height: 100%;
            }

        }

    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {
    #e-commerce-order {
        .center {
            .header {
                .header-content {
                    .breadcrumb {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #e-commerce-order {
        .center {
            .header {
                .header-content {
                    .breadcrumb {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.order-detail-form-container {
    padding: 24px;
    margin-bottom: 24px;
}

md-dialog {
    md-toolbar {
        md-autocomplete {
            input[type="search"] {
                color: #fff;
                cursor: pointer;
                font-size: 20px;
            }
            button {
                display: none;
            }
        }
    }
}

.selectSelectHeader {

    /* Please note: All these selectors are only applied to children of elements with the 'selectdemoSelectHeader' class */
    .header-searchbox {
        border: none;
        height: 100%;
        outline: none;
        padding: 0;
        width: 100%;
    }

    .select-header {
        align-items: center;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;
        display: flex;
        height: 48px;
        padding-left: 10.667px;
        position: relative;
        width: auto;
    }

    md-content._md {
        max-height: 240px;
    }
}
