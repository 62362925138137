.clear-autocomplete {
    opacity: 0;
    transform: translate(0, -100%);
    transition: visibility 0s, opacity 0.25s linear;
    visibility: hidden;

    &.visible {
        visibility: visible;
        opacity: 1;
        cursor: pointer;
    }

    md-icon {
        font-size: 20px;
    }
}
