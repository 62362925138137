// table #purchase-orders-products {
//     thead, tbody {
//         & > th > td,
//         & > tr > td {
//             &:nth-child(1) {
//                 min-width: 50px;
//                 md-input-container {
//                     width: 50px;
//                 }
//             }
//         }
//     }
// }

// #purchase-orders-products {
//     tbody > tr > td:nth-child(2) {
//         min-width: 50px;
//         md-input-container {
//             width: 50px;
//         }
//     }
// }
//

@mixin cell($width) {
    padding : 0px;
    max-width : $width * 1px;
    min-width : $width * 1px;
    width : $width * 1px;
}

#purchase-orders {
    .cell-40 { @include cell(40);}
    .cell-80 { @include cell(80);}
    .cell-120 { @include cell(120);}
}
