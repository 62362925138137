
.page-layout.simple.right-sidenav > .center .header,
.page-layout.simple.tabbed > .header,
.page-layout.simple.fullwidth > .header {
    background-image: url('/assets/images/backgrounds/radiator-header-bg.jpg');
}

.page-layout > .header .breadcrumb,
.page-layout > .header .breadcrumb md-icon {
    color: #FFF;
}

// md-tabs fix
md-pagination-wrapper {
    width: auto!important;
}

.white-fg md-input-container label {
    color: #FFF !important;
}

md-input-container {
    .hint {
        /* Position the hint */
        position: absolute;
        left: 2px;
        right: auto;
        bottom: 7px;
        /* Copy styles from ng-messages */
        font-size: 12px;
        line-height: 14px;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        /* Set our own color */
        color: grey;
        &.ng-hide, &.ng-enter {
            bottom: 26px;
            opacity: 0;
        }
        &.ng-leave {
            &.ng-leave-active {
                bottom: 26px;
                opacity: 0;
            }
            bottom: 7px;
            opacity: 1;
        }
        &.ng-enter.ng-enter-active {
            bottom: 7px;
            opacity: 1;
        }
    }

    &.no-md-errors-spacer {
        .md-errors-spacer {
            display: none;
        }
    }

    md-progress-circular {
        top: 7px;
    }

    .md-input {
        &.no-border {
            border-bottom-width: 0;
            margin-bottom: 1px;
            cursor: pointer;
            &:hover {
                margin-bottom: 0;
                border-bottom-width: 1px;
            }
        }
    }

    a.md-icon-button {
        md-icon {
            position: relative;
            top: 4px;
        }
    }
}

table.dataTable.va-t {
    tbody td {
        vertical-align: top;
    }
}

.dataTables_wrapper {

    padding-top: 0;
    min-height: 500px;

    .bottom {
        border-top: none;
    }

    table {
        margin-top: 0 !important;
    }
}

.dataTables_length select {
    background-color: #FFF;
    width: 45px;
    cursor: pointer;
}

table.dataTable tbody tr.selected {
    // background-color: material-color('grey', '100');
    background-color: material-color('blue', '200');
}

table.dataTable.hover tbody tr.odd:hover.selected,
table.dataTable.hover tbody tr.even:hover.selected {
    background-color: material-color('blue', '700');
    color: #FFF;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    border: 1px solid #FFF;
    background: rgba(0, 0, 0, 0.12) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: inherit;
}

table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr > td:first-child:before {
    content: "\e9a7";
    font-family: 'icomoon';
    box-shadow: none;
}

table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before {
    content: "\e9a8";
}

table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.child > td:first-child:before {
    display: none;
}

table.dataTable.dtr-inline.collapsed.dtr-inline.collapsed > tbody > tr.child > td:first-child ul {
    padding-left: 0;
}

.md-success {
    @extend .md-green-500-bg;
}

.md-danger {
    @extend .md-red-500-bg;
}

span.highlight{
    background: #ff0;
}

.dt-loading {
    width: 100%;

    h4 {
        text-align: center;
    }

}

.white-form-text {
    background-color: transparent !important;
    .title {
        input {
            padding-bottom: 15px;
            height: 40px;
        }
    }
    .md-input {
        color: #fff !important;
        border-color: rgba(255, 255, 255, 0.2) !important;
    }
    md-input-container.md-input-focused {
        .md-input {
            border-color: #fff !important;
        }
    }
}

#a0-lock.a0-theme-default .a0-panel * {
    font-family: "Roboto", "Avenir Next", Avenir,-apple-system, BlinkMacSystemFont, Roboto, Hevetica, sans-serif !important; 
}

#vertical-navigation .navigation-header .logo .logo-text {
    font-size: 12px !important;
}